























































































































































































































































































































































































































































































import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  ValidationRule
} from 'vuelidate/lib/validators'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import CustomTextInput from '../create/CustomTextInput/index.vue'
import controller from '@/app/ui/controllers/ManageCourierController'
import { Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import WarningIcon from "@/app/ui/assets/warning_icon_modal.vue";
import { CourierOnLeave, ManageCourierBankInformation,  Partner, TruckVendor } from '@/domain/entities/ManageCourier'
import SuccessIcon from "@/app/ui/assets/success_icon_modal.vue";
import CalendarIcon from '@/app/ui/assets/icon_calendar_fill.vue'
import {
  EnumOptions,
  EnumTypeCourier,
  EnumTypeVehicle,
} from '@/app/infrastructures/misc/Constants/manageCourier'
import ManageCourierCheckbox from "@/app/ui/views/ManageUserAndCourier/components/ManageCourierCheckbox/index.vue";
import DateTimePicker from "@/app/ui/components/DateTimePickerV2/index.vue";
import TimePicker from "@/app/ui/components/TimePicker/index.vue";
import WarningTrianglesIcon from '@/app/ui/assets/icon_warning_triangles_new.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  EnumOption,
  IFormTypeEdit,
  IHasChangedType,
  IHistoryDetail,
  IParams,
} from '@/data/infrastructures/misc/interfaces/manageCourier'
import InputPhoneInternational from '@/app/ui/components/InputPhoneInternational/index.vue'
import InputSuggestion from '../../components/InputSuggestion/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'

interface ManageCourierDetail {
  courierId: number
  fullName: string
  role: string
  phoneNumber: string
  vehicleType: string
  vehiclePlate: string
  partnerType: string
  partnerName: string
  courierType: string
  status: string
  bankInformation: ManageCourierBankInformation
  announcementCourierType: string
  courierCreatedAt?: string
  courierCreatedBy?: string
  courierUpdatedAt?: string
  courierUpdatedBy?: string
  courierOnLeave?: CourierOnLeave[]
  truckVendor?: TruckVendor
  courierIsCod?: string
}

export interface IValidations {
  form: {
    driverName: {
      required: () => ValidationRule
    }
    phoneNumber: {
      required: () => ValidationRule,
      maxLength: () => boolean,
      minLength: () => boolean
    }
    courierType: {
      required: () => ValidationRule
    }
    courierStatus?: {
      required: () => ValidationRule
    }
    announcementCourierType: {
      required: () => ValidationRule
    }
    vehicleType: {
      required: () => ValidationRule
    }
    vehiclePlate: {
      required: () => ValidationRule
    }
    partnerName: {
      required: () => ValidationRule
    }
    bankAccountName: {
      required: ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
      isCharactersAllowed: (value: string) => boolean
    }
    bankName: {
      required: ValidationRule
    }
    bankAccount: {
      required: ValidationRule
    },
    vendorId: {
      maxLength: ValidationRule
    },
    vendorName: {
      required: ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    },
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DropdownSelect,
    TextInput,
    CustomTextInput,
    Modal,
    WarningIcon,
    SuccessIcon,
    CalendarIcon,
    ManageCourierCheckbox,
    DateTimePicker,
    TimePicker,
    WarningTrianglesIcon,
    LoadingOverlay,
    InputPhoneInternational,
    InputSuggestion,
    RadioButton
  },
})
export default class EditCourier extends Vue  {
  controller = controller
  successModal = false
  confirmationModal = false
  scheduleAlreadyChoosenModal = false
  created(): void {
    controller.getCourierDetail(({ id: this.$route.params.id}))
  }
  get courierDetail(): ManageCourierDetail {
    return controller.manageCourierDetail
  }
  get bankAccountNameErrorMessage(): string {
    if (!this.form.bankAccountName) {
      return 'Nama Akun Bank harus diisi'
    } else if (!this.isCharactersAllowed(this.form.bankAccountName)) {
      return 'Nama penerima hanya diperbolehkan spasi, alfanumerik, karakter "." dan ","'
    }
    return 'Nama Akun Bank harus diantara 3 - 30 karakter'
  }
  form: IFormTypeEdit = {
    driverName: "",
    phoneNumber: "",
    courierType: [] as unknown as EnumOption ,
    announcementCourierType: [] as unknown as EnumOption,
    courierStatus: [] as unknown as EnumOption,
    vehicleType: [] as unknown as EnumOption,
    vehiclePlate: "",
    partnerName: [] as unknown as EnumOption,
    bankAccountName: "",
    bankName: [] as unknown as EnumOption,
    bankAccount: "",
    courierOnLeave: [] as CourierOnLeave[],
    phoneCode: '',
    isHaveVendor: false,
    vendorId: "",
    vendorName: "",
    courierIsCod: ""
  }
  hasChanged: IHasChangedType = {
    driverName: false,
    phoneNumber: false,
    courierType: false,
    announcementCourierType: false,
    courierStatus: false,
    vehicleType: false,
    vehiclePlate: false,
    partnerName: false,
    bankAccountName: false,
    bankName: false,
    bankAccount: false,
    vendorId: false,
    vendorName: false,
    courierIsCod: false,
  }
  parameters: IParams = {
    search: '',
    agentType: this.form.courierType
  }
  hourEndOptions = Array.from({length: 24}, (_n, index) => index)
  minuteOptions = Array.from({length: 60}, (_n, index) => index)
  courierType: EnumOptions = [
      {label: "KVP", value: "KVP"},
      {label: "POS", value: "POS"},
      {label: "Truck", value: "TRUCK"},
      {label: "Internal", value: "INTERNAL"},
  ]
  vehicleType: EnumOptions  = [
      {label: "Motorcycle", value: "MOTORCYCLE"},
      {label: "Truck", value: "TRUCK"}

  ]
  statusType: EnumOptions  = [
      {label: "Active", value: "ACTIVE"},
      {label: "Banned", value: "BANNED"}
  ]
  bankList: EnumOptions  = [
      {label: "BCA", value: "BCA"},
      {label: "Mandiri", value: "MANDIRI"},
      {label: "Maybank", value: "MAYBANK"},
      {label: "BRI", value: "BRI"},
  ]
  partnerList: EnumOptions = []
  courierOnLeaveTimer = 0

    vendorIdOptions: EnumOptions = []
    vendorNameOptions: EnumOptions = []

  get params(): Record<string, string> {
    return {
      search: this.parameters.search.toUpperCase(),
      agent_type: this.form.courierType.value
    }
  }

  get isCourierTypeTruck(): boolean {
    if (this.form.courierType.value) {
      return (this.form.courierType.value as string).toUpperCase() === EnumTypeCourier.TRUCK
    }
    return false
  }

  get isCourierTypePOS(): boolean {
    if (this.form.courierType.value) {
      return (this.form.courierType.value as string).toUpperCase() === EnumTypeCourier.POS
    }
    return false
  }

  get vendorIdErrorMessage(): string {
    return 'Vendor ID max 50 characters'
  }

  get vendorNameErrorMessage(): string {
    if (!this.form.vendorName) {
      return 'Vendor Name is required'
    }
    return 'Vendor Name must be between 3 - 50 characters'
  }

  get historyDetail(): IHistoryDetail {
    return {
      createdAt: this.courierDetail.courierCreatedAt ?
        Utils.formatDateWithIDLocale(
          this.courierDetail.courierCreatedAt,
          'DD MMMM, HH:mm WIB'
        ) : '-',
      createdBy: this.courierDetail.courierCreatedBy || '-',
      updatedAt: this.courierDetail.courierUpdatedAt ?
        Utils.formatDateWithIDLocale(
          this.courierDetail.courierUpdatedAt,
          'DD MMMM, HH:mm WIB'
        ) : '-',
      updatedBy: this.courierDetail.courierUpdatedBy || '-'
    }
  }

  get sortedCourierOnLeaveSchedule(): CourierOnLeave[] {
    return this.form.courierOnLeave
      .sort((a, b) => new Date(<string>a.dateLeave).getTime() - new Date(<string>b.dateLeave)
      .getTime())
      .filter((schedule) => {
        return !schedule.isDelete
      })
  }

  get hoursNow(): number {
    return parseInt(Utils.formatDateWithIDLocale(new Date().toISOString(), 'HH'))
  }

  private isTodayDate(date: string): boolean {
    return Utils.formatDate(date, 'YYYY-MM-DD') === Utils.formatDate(new Date().toISOString(), 'YYYY-MM-DD')
  }

  private hourStartOptions(date: string): number[] {
    return this.isTodayDate(date)
      ? Array.from(
        {
          length: 23 - this.hoursNow
        },
        (_n, index) => index + this.hoursNow + 1)
      : Array.from({length: 23}, (_n, index) => index)
  }

  private fetchPartnersData(): void {
    if (this.params.search && this.params.agent_type) {
      controller.getAllPartner(this.params)
    }
  }

  private onSearchPartnerName = Utils.debounce((e: string) => {
    this.parameters.search = e
    this.fetchPartnersData()
  }, 400)

  private formatedCourierLeaveDate(date: string): string {
    return Utils.formatDateWithIDLocale(date, 'dddd, DD MMMM YYYY')
  }

  private findCourierOnLeaveScheduleIndex(date: string): number {
    return this.form.courierOnLeave.findIndex(
      (schedule) => schedule.dateLeave === date
    )
  }

  private deleteSchedule(date: string): void {
    const index = this.findCourierOnLeaveScheduleIndex(date)
    if (this.form.courierOnLeave[index].id) {
      this.form.courierOnLeave[index].isDelete = true
    } else {
      this.form.courierOnLeave.splice(index, 1)
    }
  }

  private onCheckCustomSchedule(date: string): void {
    const index = this.findCourierOnLeaveScheduleIndex(date)
    const newDate = new Date(date)
    if (this.isTodayDate(date)) {
      newDate.setHours(this.hoursNow + 1, new Date().getMinutes() + 1, 0, 0)
    } else {
      newDate.setHours(0, 0, 0, 0)
    }
    this.form.courierOnLeave[index].isCustomLeave = !this.form.courierOnLeave[index].isCustomLeave
    if (this.form.courierOnLeave[index].isCustomLeave) {
      this.form.courierOnLeave[index].startTime = newDate.toISOString()
      if (this.isTodayDate(date)) {
        newDate.setHours(new Date().getHours() + 2)
      } else {
        newDate.setHours(1, 0, 0, 0)
      }
      this.form.courierOnLeave[index].endTime = newDate.toISOString()
    } else {
      this.form.courierOnLeave[index].startTime = newDate.toISOString()
      newDate.setHours(23, 59, 0, 0)
      this.form.courierOnLeave[index].endTime = newDate.toISOString()
    }
  }

  private onDatepickerInput(value: Date): void {
    const dateLeave = Utils.formatDate(value.toISOString(), 'YYYY-MM-DD')
    const newDate = new Date(dateLeave)
    const newEndDate = new Date(dateLeave)
    newDate.setHours(0, 0, 0, 0)
    newEndDate.setHours(23, 59, 0, 0)
    const index = this.findCourierOnLeaveScheduleIndex(dateLeave)
    if (index === -1) {
      this.form.courierOnLeave.push({
        dateLeave: dateLeave,
        startTime: newDate.toISOString(),
        endTime: newEndDate.toISOString(),
        isCustomLeave: false,
      })
    } else {
      this.scheduleAlreadyChoosenModal = true
    }
  }

  private onCustomScheduleInput(type: string, value: Date): void {
    const dateLeave = Utils.formatDate(value.toISOString(), 'YYYY-MM-DD')
    const index = this.findCourierOnLeaveScheduleIndex(dateLeave)
    if (type === 'start_time') {
      if (this.isTodayDate(value.toISOString())) {
        if (value.getTime() - new Date().getTime() < 60 * 60 * 1000) {
          value.setHours(this.hoursNow + 1, new Date().getMinutes() + 1, 0, 0)
        }
      }
      this.form.courierOnLeave[index].startTime = value.toISOString()
      this.validateScheduleTime(index, new Date(<string>this.form.courierOnLeave[index].endTime))
    } else {
      if (this.validateScheduleTime(index, value)) {
        this.form.courierOnLeave[index].endTime = value.toISOString()
      }
    }
  }

  private validateScheduleTime(index: number, value: Date): boolean {
    if (
      (value.getTime() - new Date(<string>this.form.courierOnLeave[index].startTime).getTime()) <
      60 * 60 * 1000
    ) {
      this.form.courierOnLeave[index].endTime = new Date(
        new Date(<string>this.form.courierOnLeave[index].startTime).getTime() + (60 * 60 * 1000)
      ).toISOString()
      return false
    } else {
      return true
    }
  }

  private checkIsTodayDate(date: string): boolean {
    return Utils.formatDate(date, 'YYYY-MM-DD') === Utils.formatDate(new Date().toISOString(), 'YYYY-MM-DD')
  }

  private isLessThanOneHour(schedule: CourierOnLeave): boolean {
    if (!schedule.id) return false
    return new Date(<string>schedule.startTime).getTime() - new Date().getTime() < 60 * 60 * 1000
  }

  private setCourierOnLeaveTimer(id: number, startTime: string): void {
    const oldData = this.controller.manageCourierDetail.courierOnLeave?.find((item) => item.id == id)
    const diffTime = new Date(startTime).getTime() - new Date().getTime() - (1000 * 60 * 60)

    this.courierOnLeaveTimer = setTimeout(() => {
      this.form.courierOnLeave = this.form.courierOnLeave.map((item) => {
        if (item.id === id) {
          return {
            ...oldData,
            isDisabled: true
          }
        }
        return item
      })
    }, diffTime > 0x7FFFFFFF ? 0x7FFFFFFF : diffTime)
  }

  private onPhoneCodeChange(code: string): void {
    this.form.phoneCode = code
  }

  private maxLengthIndonesianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phoneNumber.length <= 13
    }
    return true
  }

  private minLengthIndosianPhoneNumber(): boolean {
    if (this.form.phoneCode === '+62') {
      return this.form.phoneNumber.length >= 9
    }
    return true
  }

  public onCheckIsHaveVendor(): void {
    this.form.isHaveVendor = !this.form.isHaveVendor
    if (!this.form.isHaveVendor) {
      this.form.vendorId = ""
      this.form.vendorName = ""
      this.vendorIdOptions = []
      this.vendorNameOptions = []
    }
  }

  public onChangeVendorID = Utils.debounce(() => {
    this.hasChanged.vendorId = true
    this.getVendorSuggestions('id')
  }, 400)

  public onChangeVendorName = Utils.debounce(() => {
    this.hasChanged.vendorName = true
    this.getVendorSuggestions('name')
  }, 400)

  private getVendorSuggestions(type: string): void {
    if (
      (type === 'id' && !this.form.vendorId) ||
      (type === 'name' && !this.form.vendorName)
    ) {
      return
    }
    this.controller.getTruckVendorList({
      params: {
        [`vendor_${type}`]: type === 'id' ?
          this.form.vendorId :
          this.form.vendorName
      },
      type
    })
  }

  @Validations()
  validations(): IValidations {
    return {
      form: {
        driverName: { required },
        phoneNumber: {
          required,
          maxLength: this.maxLengthIndonesianPhoneNumber,
          minLength: this.minLengthIndosianPhoneNumber
        },
        courierType: { required },
        announcementCourierType: { required },
        courierStatus: { required },
        vehicleType: { required },
        vehiclePlate: { required },
        partnerName: { required },
        bankAccountName: {
          required: requiredIf(() => {
            if (this.form.courierType.value) {
              return this.form.courierType.value.toUpperCase() !== EnumTypeCourier.TRUCK && !this.isCourierTypeInternal
            }
            return false
          }),
          minLength: minLength(3),
          maxLength: maxLength(30),
          isCharactersAllowed(value: string): boolean {
            return this.isCharactersAllowed(value)
          }
        },
        bankName: {required: requiredIf(() => {
          if (this.form.courierType.value) {
            return this.form.courierType.value.toUpperCase() !== EnumTypeCourier.TRUCK && !this.isCourierTypeInternal
          }
          return false
        })},
        bankAccount: {required: requiredIf(() => {
          if (this.form.courierType.value) {
            return this.form.courierType.value.toUpperCase() !== EnumTypeCourier.TRUCK && !this.isCourierTypeInternal
          }
          return false
        })},
        vendorId: {
          maxLength: maxLength(50),
        },
        vendorName: {
          required: requiredIf(() => {
            if (this.form.courierType.value) {
              return (this.form.courierType.value as string).toUpperCase() == EnumTypeCourier.TRUCK && this.form.isHaveVendor
            }
            return false
          }),
          minLength: minLength(3),
          maxLength: maxLength(50),
        }
      },
    }
  }

  @Watch("courierDetail")
  onCourierDetailChanged(val: ManageCourierDetail): void {
    this.form = {
      driverName: val.fullName,
      phoneNumber: val.phoneNumber,
      courierType: this.courierType.find(
        (item) => item.value === val.courierType
      ) || [] as unknown as EnumOption,
      announcementCourierType: this.courierType.find(
        (item) => item.value === val.announcementCourierType
      ) || [] as unknown as EnumOption,
      courierStatus: this.statusType.find(
        (item) => item.value === val.status
      ) || [] as unknown as EnumOption,
      vehicleType: this.vehicleType.find(
        (item) => item.value === val.vehicleType
      ) || [] as unknown as EnumOption,
      vehiclePlate: val.vehiclePlate,
      partnerName: this.partnerList[-1],
      bankAccountName: val.bankInformation.bankAccountName,
      bankName: this.bankList.find(
        (item) => item.value === val.bankInformation.bankId
      ) || [] as unknown as EnumOption,
      bankAccount: val.bankInformation.bankAccount,
      courierOnLeave: val.courierOnLeave?.length ? val.courierOnLeave.map((schedule) => {
        return {
          ...schedule,
          isDelete: false,
          isCustomLeave: !!schedule.isCustomLeave
        }
      }) : [],
      phoneCode: '',
      isHaveVendor: false,
      vendorId: val.truckVendor?.vendorId || "",
      vendorName: val.truckVendor?.vendorName || "",
      courierIsCod: val.courierIsCod || ""
    }

    this.parameters.search = val.partnerName.substring(
      val.partnerName.indexOf('[') + 1,
      val.partnerName.indexOf(']')
    )
    this.parameters.agentType = this.form.courierType

    this.fetchPartnersData()

    if (this.form.vendorId || this.form.vendorName) {
      this.form.isHaveVendor = true
    }
  }

  @Watch('courierDetail.courierOnLeave')
  onChangeCourierOnLeaveData(data: CourierOnLeave[]): void {
   this.form.courierOnLeave = data.map((item) => {
    if (!this.checkIsTodayDate(<string>item.dateLeave)) {
      this.setCourierOnLeaveTimer(<number>item.id, <string>item.startTime)
      return item
    }
    if (item.isCustomLeave) {
      if (this.isLessThanOneHour(item)) {
        this.setCourierOnLeaveTimer(<number>item.id, <string>item.startTime)
        return {
          ...item,
          isDisabled: true,
        }
      }
      this.setCourierOnLeaveTimer(<number>item.id, <string>item.startTime)
      return {
        ...item,
        isDisabled: false,
      }
    } else {
      return {
        ...item,
        isDisabled: true,
      }
    }
   })
  }

  @Watch('controller.partnerData')
  setPartnerData(data: Partner[]): void {
    const res = data.map((item) => {
      return {
        label: `${item.name} [${item.agentCode}]`,
        value: `${item.name} [${item.agentCode}]`
      }
    })

    this.partnerList = res

    if (!this.form.partnerName) {
      this.form.partnerName = this.partnerList[0]
    }
  }

  @Watch('form.driverName')
  onChangeDriverName(data: string): void {
    // remove script tags
    this.form.driverName = Utils.removeScriptTags(data)

    // remove special characters
    this.form.driverName = Utils.removeSpecialCharacters(this.form.driverName)

    // remove html tags
    this.form.driverName = Utils.removeHTMLTags(this.form.driverName)
  }

  @Watch('form.vehiclePlate')
  onChangeVehiclePlate(data: string): void {
    // remove script tags
    this.form.vehiclePlate = Utils.removeScriptTags(data)

    // remove special characters
    this.form.vehiclePlate = Utils.removeSpecialCharacters(this.form.vehiclePlate)

    // remove html tags
    this.form.vehiclePlate = Utils.removeHTMLTags(this.form.vehiclePlate)
  }

  @Watch('form.bankAccountName')
  onChangeBankAccountName(data: string): void {
    // remove script tags
    this.form.bankAccountName = Utils.removeScriptTags(data)

    // remove special characters
    this.form.bankAccountName = Utils.removeSpecialCharacters(this.form.bankAccountName)

    // remove html tags
    this.form.bankAccountName = Utils.removeHTMLTags(this.form.bankAccountName)
  }

  @Watch('form.bankAccount')
  onChangeBankAccount(data: string): void {
    // remove script tags
    this.form.bankAccount = Utils.removeScriptTags(data)

    // remove special characters
    this.form.bankAccount = Utils.removeSpecialCharacters(this.form.bankAccount)

    // remove html tags
    this.form.bankAccount = Utils.removeHTMLTags(this.form.bankAccount)
  }

  get isVehicleTypeTruck(): boolean {
    if (this.form.vehicleType.value) {
      return (this.form.vehicleType.value as string).toUpperCase() === EnumTypeVehicle.TRUCK
    }
    return false
  }
  get isCourierTypeInternal(): boolean {
    if (this.form.courierType.value) {
      return (this.form.courierType.value as string).toUpperCase() === EnumTypeCourier.INTERNAL
    }
    return false
  }

  private onEditCourier(): void {
    this.confirmationModal = false
    if (!this.$v.form.$invalid) {
      this.controller.updateCourier({
        courierId: this.$route.params.id,
        fullname: this.form.driverName,
        phoneNumber: this.form.phoneCode + this.form.phoneNumber,
        courierType: this.form.courierType.value,
        courierStatus: this.form.courierStatus.value,
        vehicleType: this.form.vehicleType.value,
        vehiclePlate: this.form.vehiclePlate,
        partnerName: this.form.partnerName.value,
        bankAccountName: this.form.bankAccountName,
        bankId: this.form.bankName.value,
        bankAccount: this.form.bankAccount,
        announcementCourierType: this.form.announcementCourierType.value,
        timeZone: Utils.formatDateWithIDLocale(new Date().toISOString(), 'Z'),
        courierOnLeave: this.form.courierOnLeave.filter(
          (filter) => !filter.isDisabled && !this.isLessThanOneHour(filter))
            .map((schedule) => {
              return {
                ...schedule,
                startTime: Utils.formatDateWithIDLocale(<string>schedule.startTime, 'HH:mm'),
                endTime: Utils.formatDateWithIDLocale(<string>schedule.endTime, 'HH:mm'),
              }
        }),
        truckVendor:  {
          vendorId: this.form.vendorId,
          vendorName: this.form.vendorName,
        },
        courierIsCod: this.form.courierIsCod
      })
    }

    this.hasChanged = {
        driverName: true,
        phoneNumber: true,
        courierType: true,
        courierStatus: true,
        announcementCourierType: true,
        vehicleType: true,
        vehiclePlate: true,
        partnerName: true,
        bankAccountName: true,
        bankName: true,
        bankAccount: true,
        vendorId: true,
        vendorName: true,
        courierIsCod: true
    }
  }

  get successModalEdit(): boolean {
    return controller.modalSuccessEdit;
  }

  private setSuccessModal(): void {
    this.$router.push('/manage-user-&-courier/manage-courier')
    controller.setModalSuccessEdit(false)
  }

  private isCharactersAllowed(str: string): boolean {
    // this regex below will check character other than the allowed character
    // input only allow space, alphanumeric, comma (,) and period (.)
    const regex = /([^a-zA-Z0-9., ])+/g

    // return is negated for vuelidate
    return !regex.test(str)
  }

  beforeDestroy(): void {
    clearTimeout(this.courierOnLeaveTimer)
  }

  @Watch('controller.truckVendorIdData')
  private setTruckVendorIdOptions(vendors: TruckVendor[]): void {
    this.vendorIdOptions = vendors.map((vendor) => {
      return {
        label: <string>vendor.vendorId,
        value: <string>vendor.vendorId
      }
    })
  }

  @Watch('controller.truckVendorNameData')
  private setTruckVendorNameOptions(vendors: TruckVendor[]): void {
    this.vendorNameOptions = vendors.map((vendor) => {
      return {
        label: <string>vendor.vendorName,
        value: <string>vendor.vendorName
      }
    })
  }
}
